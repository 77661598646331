a {
  color: blue;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Georgia";
  line-height: 1.15;
  position: absolute;
}

.inter {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
